@use '@fixed-size' as *;
@use '@queries' as *;
@use '@typography' as *;

.badge {
  border-radius: var(--cornerradius20);
  display: flex;
  justify-content: center;
  align-items: center;

  &.one-number {
    @include singleFixedSize(15px);
    border-radius: var(--cornerradius20);
  }

  &.two-numbers {
    @include fixedSize(23px, 15px);
    border-radius: var(--cornerradius20);
  }

  &.three-numbers {
    @include fixedSize(30px, 15px);
    border-radius: var(--cornerradius20);
  }

  .count {
    @include typography-xs;
    @include weight-medium;
    color: var(--cst-badge-text);

    @include min-1024-break {
      @include typography-s;
    }
  }

  &:not(.badge--mobile) {
    @include min-1024-break {
      &.one-number {
        @include singleFixedSize(18px);
        border-radius: var(--cornerradius20);
      }

      &.two-numbers {
        @include fixedSize(26px, 18px);
        border-radius: var(--cornerradius20);
      }

      &.three-numbers {
        @include fixedSize(34px, 18px);
        border-radius: var(--cornerradius20);
      }
    }
  }
}
