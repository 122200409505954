.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.list-container {
  height: max-content;
  min-height: max-content;
  max-height: max-content;
}
