.typography {
  color: var(--alias-typography-primary);
  font-family: Inter, sans-serif;
  margin: 0;
  padding: 0;
}

.decoration {
  &--normal {
    text-decoration: none;
  }

  &--underline {
    text-decoration: underline;
  }

  &--line-through {
    text-decoration: line-through;
  }
}

.weight {
  &--regular {
    font-weight: var(--font-weight-regular);
  }

  &--medium {
    font-weight: var(--font-weight-medium);
  }

  &--semi-bold {
    font-weight: var(--font-weight-semi-bold);
  }

  &--bold {
    font-weight: var(--font-weight-bold);
  }
}

.size {
  &--xs {
    font-size: var(--font-size-x-small);
    line-height: var(--line-height-x-small);
  }

  &--s {
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
  }

  &--base {
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
  }

  &--m {
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
  }

  &--l {
    font-size: var(--font-size-large);
    line-height: var(--line-height-large);
  }

  &--xl {
    font-size: var(--font-size-x-large);
    line-height: var(--line-height-x-large);
  }

  &--2xl {
    font-size: var(--font-size-2x-large);
    line-height: var(--line-height-2x-large);
  }

  &--3xl {
    font-size: var(--font-size-3x-large);
    line-height: var(--line-height-3x-large);
  }

  &--4xl {
    font-size: var(--font-size-4x-large);
    line-height: var(--line-height-4x-large);
  }

  &--5xl {
    font-size: var(--font-size-5x-large);
    line-height: var(--line-height-5x-large);
  }
}
