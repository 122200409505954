@use '@queries' as *;

.avatar {
  display: flex;
  position: relative;
  height: 22px;
  width: 22px;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 50%;

  cursor: pointer;

  &--bordered {
    box-shadow: inset 0 0 0 1.4px var(--global-purple600);
    &:hover {
      box-shadow: inset 0 0 0 1.4px var(--global-purple700);
    }
  }

  &__img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      object-fit: cover;
      width: 100%;
      height: 100%;
      overflow: hidden;
      clip-path: circle();
    }

    &--badge {
      position: absolute;
      z-index: 2;
      top: 2px;
      right: 1px;
    }
  }

  @include min-428-break {
    height: 28px;
    width: 28px;

    .avatar__img-container {
      height: 23px;
      width: 23px;
    }

    &.avatar--bordered {
      box-shadow: inset 0 0 0 1.75px var(--global-purple600);
      &:hover {
        box-shadow: inset 0 0 0 1.75px var(--global-purple700);
      }
    }
  }

  &:not(.avatar--mobile) {
    @include min-1024-break {
      height: 46px;
      width: 46px;

      .avatar__img-container {
        height: 38px;
        width: 38px;
      }

      &.avatar--bordered {
        box-shadow: inset 0 0 0 2px var(--global-purple600);

        &:hover {
          box-shadow: inset 0 0 0 2.5px var(--global-purple700);
        }
      }
    }
  }
}
