@use '@queries' as *;

.button {
  text-decoration: none;
  border: 0;
  box-sizing: border-box;
  border-radius: var(--cornerradius10);
  font-size: 15px;
  font-weight: bold;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0 10px;
  @include min-428-break {
    padding: 0 13px;
  }
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.text {
  margin: 0;
}

.primary {
  color: var(--cst-buttons-primary-text-default);
  span.text {
    color: var(--cst-buttons-primary-text-default);
  }

  background: var(--cst-buttons-primary-background-default);
  border-width: 0;
}

.primary:active {
  span.text {
    color: var(--cst-buttons-primary-text-pressed);
  }
  color: var(--cst-buttons-primary-text-pressed);
  background: var(--cst-buttons-primary-background-pressed);
  box-shadow: 0 0 0 4px #6941c633;
}

.primary:focus {
  span.text {
    color: var(--cst-buttons-primary-text-focused);
  }
  color: var(--cst-buttons-primary-text-focused);
  background: var(--cst-buttons-primary-background-focused);
}

.primary:disabled {
  span.text {
    color: var(--cst-buttons-primary-text-disabled);
  }
  color: var(--cst-buttons-primary-text-disabled);
  background: var(--cst-buttons-primary-background-disabled);
}

@media (hover: hover) {
  .primary:hover:not(:disabled):not(.button__disabled) {
    color: var(--cst-buttons-primary-text-hover);
    background: var(--cst-buttons-primary-background-hover);

    span.text {
      color: var(--cst-buttons-primary-text-hover);
    }

    .primary:active {
      color: var(--cst-buttons-primary-text-pressed);
      background: var(--cst-buttons-primary-background-pressed);

      span.text {
        color: var(--cst-buttons-primary-text-pressed);
      }
    }
  }
}

.secondary {
  span.text {
    color: var(--cst-buttons-secondary-text-default);
  }
  color: var(--cst-buttons-secondary-text-default);
  background: var(--cst-buttons-secondary-background-default);
  box-shadow: inset 0 0 0 2px var(--cst-buttons-secondary-outline-default);
}

.secondary:active {
  span.text {
    color: var(--cst-buttons-secondary-text-pressed);
  }
  color: var(--cst-buttons-secondary-text-pressed);
  background: var(--cst-buttons-secondary-background-pressed);
  box-shadow: 0 0 0 4px #6941c633;
}

.secondary:focus {
  span.text {
    color: var(--cst-buttons-secondary-text-focused);
  }
  color: var(--cst-buttons-secondary-text-focused);
  background: var(--cst-buttons-secondary-background-focused);
}

.secondary:disabled {
  span.text {
    color: var(--cst-buttons-secondary-text-disabled);
  }
  color: var(--cst-buttons-secondary-text-disabled);
  background: var(--global-white);
}

@media (hover: hover) {
  .secondary:hover:not(:disabled):not(.button__disabled) {
    color: var(--cst-buttons-secondary-text-hover);
    background: var(--global-white);
    span.text {
      color: var(--cst-buttons-secondary-text-hover);
    }

    .secondary:active {
      color: var(--cst-buttons-secondary-text-pressed);
      background: var(--cst-buttons-secondary-background-pressed);
    }
    span.text {
      color: var(--cst-buttons-secondary-text-pressed);
    }
  }
}

.tertiary {
  span.text {
    color: var(--cst-buttons-tertiary-text-default);
  }
  color: var(--cst-buttons-tertiary-text-default);
  background: var(--cst-buttons-tertiary-background-default);
  box-shadow: inset 0 0 0 2px var(--cst-buttons-tertiary-outline-default);
}

.tertiary:focus {
  span.text {
    color: var(--cst-buttons-tertiary-text-focused);
  }
  color: var(--cst-buttons-tertiary-text-focused);
  background: var(--cst-buttons-tertiary-background-focused);
}

.tertiary:disabled {
  span.text {
    color: var(--cst-buttons-tertiary-text-disabled);
  }
  color: var(--cst-buttons-tertiary-text-disabled);
  background: var(--global-white);
}

@media (hover: hover) {
  .tertiary:hover:not(:disabled):not(.button__disabled) {
    span.text {
      color: var(--cst-buttons-tertiary-text-hover);
    }
    color: var(--cst-buttons-tertiary-text-hover);
    background: var(--cst-buttons-tertiary-background-hover);
  }

  .tertiary:active:not(:disabled):not(.button__disabled) {
    span.text {
      color: var(--cst-buttons-tertiary-text-pressed);
    }
    color: var(--cst-buttons-tertiary-text-pressed);
    background: var(--cst-buttons-tertiary-background-pressed);
  }
}

.quaternary {
  span.text {
    color: var(--cst-buttons-quaternary-text-default);
  }
  color: var(--cst-buttons-quaternary-text-default);
  background: var(--cst-buttons-quaternary-background-default);
  border: 2px solid var(--cst-buttons-quaternary-outline-default);
}

.quaternary:focus {
  span.text {
    color: var(--cst-buttons-quaternary-text-focused);
  }
  color: var(--cst-buttons-quaternary-text-focused);
  background: var(--cst-buttons-quaternary-background-focused);
}

.quaternary:disabled {
  span.text {
    color: var(--cst-buttons-quaternary-text-disabled);
  }
  color: var(--cst-buttons-quaternary-text-disabled);
  background: var(--global-white);
}

@media (hover: hover) {
  .quaternary:hover:not(:disabled):not(.button__disabled) {
    span.text {
      color: var(--cst-buttons-quaternary-text-hover);
    }
    color: var(--cst-buttons-quaternary-text-hover);
    background: var(--cst-buttons-quaternary-background-hover);
  }
  .quaternary:active:not(:disabled):not(.button__disabled) {
    span.text {
      color: var(--cst-buttons-quaternary-text-pressed);
    }
    color: var(--cst-buttons-quaternary-text-pressed);
    background: var(--cst-buttons-quaternary-background-pressed);
    border-color: var(--cst-buttons-quaternary-outline-pressed);
  }
}

.width {
  &--full-w {
    width: 100%;
    max-width: 100%;
  }

  &--content {
    width: max-content;
    min-width: max-content;
    max-width: max-content;
  }
}

.loader {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
