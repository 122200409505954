@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@button' as *;
@use '@queries' as *;

.drop-desktop {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
  background-image: url('../../../../public/assets/drop-page/drop-page-stars-pattern.webp');
  background-size: 400px 400px;
  background-repeat: repeat;
  background-position: top;
  background-color: rgba(195, 179, 232, 0.2);
  backdrop-filter: blur(5px);

  @include fixedSize(100%, max-content);

  &-animals {
    position: absolute;
    display: flex;
    pointer-events: none;
    flex: 1;
    right: 0;
    left: 0;
    height: 100%;
    .background {
      &-black-cat,
      &-cat-pocket,
      &-lemur,
      &-parrot {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
      }

      &-black-cat {
        bottom: 0;
        right: -10px;
        background: url('../../../../public/assets/drop-page/drop-page-black-cat.webp')
          center center no-repeat;
        background-size: cover;
        @include fixedSize(74px, 50px);

        @include min-1440-break {
          @include fixedSize(146px, 100px);

          bottom: -30px;
          right: -10px;
        }

        @include min-1920-break {
          @include fixedSize(264px, 180px);
          bottom: 20px;
          right: 20px;
        }
      }

      &-cat-pocket {
        background: url('../../../../public/assets/drop-page/drop-page-cat-pocket.webp')
          center center no-repeat;
        background-size: cover;
        @include fixedSize(60px, 82px);

        bottom: 0;
        left: -10px;

        @include min-1440-break {
          bottom: -30px;
          left: -10px;
          @include fixedSize(100px, 136.71px);
        }

        @include min-1920-break {
          @include fixedSize(158px, 216px);
          bottom: -50px;
          left: 50px;
        }
      }

      &-lemur {
        background: url('../../../../public/assets/drop-page/drop-page-lemur.webp')
          center center no-repeat;
        background-size: cover;
        @include fixedSize(60px, 73.85px);

        top: 120px;
        right: 0;

        @include min-1440-break {
          @include fixedSize(80px, 98.46px);

          top: 80px;
          right: 0;
        }

        @include min-1920-break {
          @include fixedSize(130px, 160px);

          top: 40px;
          right: 0;
        }
      }

      &-parrot {
        background: url('../../../../public/assets/drop-page/drop-page-parrot.webp')
          center center no-repeat;
        background-size: cover;
        @include fixedSize(50px, 85px);

        top: 120px;
        left: 0;
        @include min-1440-break {
          @include fixedSize(80px, 138px);

          top: 80px;
          left: 0;
        }

        @include min-1920-break {
          @include fixedSize(136px, 234px);
          top: 40px;
          left: 0;
        }
      }
    }
  }
  .content-wrapper {
    display: flex;
    z-index: 2;
    width: 100%;
    padding: 18px 0;
    min-height: 100%;

    @include min-1440-break {
      padding: 18px 0 44px 0;
    }

    @include min-1920-break {
      padding: 24px 0 60px 0;
    }

    .content {
      display: flex;
      gap: 16px;
      padding: 20px;
      justify-content: center;
      width: 100%;
      min-height: 100%;
      @include min-1440-break {
        gap: 18px;
      }
      @include min-1920-break {
        gap: 24px;
      }
    }
  }
}
